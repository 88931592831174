import React from "react";
import { useApp } from "../../context/AppProvider";

import balancete_12_22 from "../../assets/balancetes/122 NOIZ - Balancete 01.2023.pdf";

import Modal from "./Modal";
import ItemBalancete from "../ItemBalancete";

const listaBalancetes = [
  { path: balancete_12_22, title: "Janeiro 2023"}
]

const Balancetes = () => {
  const { balancetes, setBalancetes } = useApp();

  return (
    <Modal
      visibility={balancetes}
      setVisibility={setBalancetes}
      id="balancetes"
    >
      <h3>Transparência</h3>
      <div className="modal-area">
        <p>Confira mensalmente nosso balancete</p>
        <ul>
          {listaBalancetes.map(balancete => <ItemBalancete key={balancete.title} path={balancete.path} title={balancete.title}/>)}
        </ul>
      </div>
    </Modal>
  );
};

export default Balancetes;
