import React from "react";
import FlexWrapper from "../FlexWrapper";

const Vaquinha = () => {
  return (
    <>
      <section className="vaquinha desk">
        <div className="container">
          <FlexWrapper>
            <h2>
              Nossa <strong>VAQUINHA</strong> pela educação
            </h2>
            <a
              className="btn-gradient-amarelo"
              href="https://www.vakinha.com.br/vaquinha/educacao-para-todos-bianka-vervloet-de-oliveira"
              target="_blank"
              rel="noreferrer"
            >
              participe da vakinha
            </a>
          </FlexWrapper>
        </div>
      </section>
      <section className="vaquinha mobile">
        <div className="container">
          <a
            className="btn-gradient-amarelo"
            href="https://www.vakinha.com.br/vaquinha/educacao-para-todos-bianka-vervloet-de-oliveira"
            target="_blank"
            rel="noreferrer"
          >
            PARTICIPE DA NOSSA <strong>VAQUINHA PELA EDUCAÇÃO</strong>
          </a>
        </div>
      </section>
    </>
  );
};

export default Vaquinha;
