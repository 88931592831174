import React from "react";
import ImgAulaReforco from "../../../../assets/images/foto-destaque-aula-reforco.jpg";
import ImgAulaReforco1 from "../../../../assets/images/foto-galeria-aula-reforco-1.jpg";
import ImgAulaReforco2 from "../../../../assets/images/foto-galeria-aula-reforco-2.jpg";
import ImgAulaReforco3 from "../../../../assets/images/foto-galeria-aula-reforco-3.jpg";
import Galery from "../../../../components/Galery";

const galeryImgs = [
  { src: ImgAulaReforco1, alt: "Aulas de Reforço 1" },
  { src: ImgAulaReforco2, alt: "Aulas de Reforço 2" },
  { src: ImgAulaReforco3, alt: "Aulas de Reforço 3" },
];

const AulaReforco = () => {
  return (
    <div className="detalhes-iniciativa">
      <main className="banner-detalhes-iniciativa-mobile bg-aulas-de-reforco" />

      <section className="detalhes-iniciativa-content container">
        <div className="row gutters">
          <div className="col grid_6">
            <h2 className="subtitulo-verde">Aulas de Reforço Escolar</h2>
            <p>
              Para crianças a partir de 4 anos, as aulas ajudam no reforço
              semanal das atividades escolares e melhor aproveitamento dos
              ensinamentos.
            </p>
          </div>
          <div className="col grid_6">
            <img src={ImgAulaReforco} alt="Aulas de Reforço" />
          </div>
        </div>
      </section>

      <Galery galeryImgs={galeryImgs} />
    </div>
  );
};

export default AulaReforco;
