import React from "react";
import Banner from "../../components/Banner";
import Blog from "../../components/Blog";
import Contact from "../../components/Contact";
import SejaSocio from "../../components/Divisors/SejaSocio";
import Vaquinha from "../../components/Divisors/Vaquinha";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import IniciativasComp from "../../components/IniciativasComp";
import Partner from "../../components/Partner";
import Project from "../../components/Project";
import Region from "../../components/Region";
import BlueWave from "../../components/BlueWave";
import Pix from "../../components/Divisors/Pix";

const Home = () => {
  return (
    <div className="index">
      <Header />
      <Banner />
      <SejaSocio />
      <Project />
      <Region />
      <Vaquinha />
      <IniciativasComp />
      <Pix />
      <Blog />
      <BlueWave />
      <Partner />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
