import React from "react";
import ImgBannerProjeto from "../../assets/images/foto-projeto.jpg";
import ImgProjeto2 from "../../assets/images/foto-missao.jpg";
import ImgProjeto3 from "../../assets/images/foto-objetivo.jpg";
import ImgProjeto4 from "../../assets/images/foto-equipe.jpg";
import FlexWrapper from "../FlexWrapper";

const Project = () => {
  return (
    <section className="projeto" id="projeto">
      <div className="container">
        <h2 className="subtitulo-branco">O projeto</h2>
        <FlexWrapper version="5">
          <div className="projeto-itens">
            <div className="projeto-foto-container">
              <img src={ImgBannerProjeto} alt="Projeto" />
              <h3>Construindo um futuro</h3>
            </div>
          </div>
          <div className="projeto-itens">
            <h3>Origem</h3>
            <p>
              A inspiração vem de uma música. De um artista que tem como marca a
              retratação de uma realidade pouco conhecida. As ruas e as
              comunidades. A letra da música NÓIZ identifica um grupo cheio de
              objetivos e propósitos. “…é nóiz, que corre a caminho do bem, nóiz
              que disse é nóiz quando não virava um vintém…”- Emicida.
            </p>
            <p>
              O projeto nasce no território mais miserável da Cidade de Deus, o
              Brejo. Em meio a ausência de água, luz e barracos, 4 amigos se
              uniram e entenderam que era preciso acreditar na evolução e na
              emancipação daquelas pessoas, através da cultura, educação,
              esporte e capacitação. Não nascemos para ser um projeto voltado
              para doação, pois entendemos o assistencialismo como emergencial.
              Amar, educar e profissionalizar são os 3 pilares que permeiam o
              conceito do nosso trabalho.
            </p>
          </div>
        </FlexWrapper>

        <FlexWrapper version="2" className="missao">
          <div className="missao-itens">
            <h3>Nossa Missão</h3>
            <p>
              Contribuir na construção do futuro de crianças, adolescentes e
              seus familiares, promovendo transformações humanas, sociais e
              econômicas, possibilitando maior justiça social através da
              educação, profissionalização e melhoria das condições de vida nas
              comunidades carentes do Rio de Janeiro.
            </p>
            <img src={ImgProjeto2} alt="Missão" />
          </div>
          <div className="missao-itens">
            <h3>Objetivo</h3>
            <p>
              Atuar na área de educação e profissionalização das pessoas com
              ações voltadas para a transformação do quadro social e cidadania.
              Dignidade e evolução são o nosso foco.
            </p>
            <img src={ImgProjeto3} alt="Objetivo" />
          </div>
          <div className="missao-itens">
            <h3>Equipe</h3>
            <p>
              Composto por integrantes de formação profissional bastante
              diversificada, o NÓIZ tem uma coisa em comum, muita garra pra
              fazer a diferença na vida das pessoas. E conta hoje com um grupo
              de voluntários bastante atuante.
            </p>
            <img src={ImgProjeto4} alt="Equipe" />
          </div>
        </FlexWrapper>
      </div>
    </section>
  );
};

export default Project;
