import React from "react";
import Slider from "react-slick";
import IconCaixote from "../../assets/images/ico-caixote-saber.png";
import ImgCaixote from "../../assets/images/photo-caixote-saber.jpg";
import IconIdiomas from "../../assets/images/ico-aula-idiomas.png";
import ImgIdiomas from "../../assets/images/photo-aulas-idioma.jpg";
import IconReforco from "../../assets/images/ico-aula-reforco.png";
import ImgReforco from "../../assets/images/photo-aula-reforco.jpg";
import IconGradua from "../../assets/images/ico-noiz-gradua.png";
import ImgGradua from "../../assets/images/photo-gradua-noiz.jpg";
import IconAtendMedico from "../../assets/images/ico-atendimento-medico.png";
import ImgAtendMedico from "../../assets/images/photo-atendimento-medico.jpg";
import IconAtendPsico from "../../assets/images/ico-atendimento-psico.png";
import ImgAtendPsico from "../../assets/images/photo-atendimento-psico.jpg";
import IconCinema from "../../assets/images/ico-sessao-cinema.png";
import ImgCinema from "../../assets/images/photo-sessao-cinema.jpg";
import IconTeatro from "../../assets/images/ico-aula-teatro.png";
import ImgTeatro from "../../assets/images/photo-teatro-infantil.jpg";
import IconNeuroPedia from "../../assets/images/ico-neuro-pediatrico.png";
import ImgNeuroPedia from "../../assets/images/photo-neuro-pediatrico.jpg";
import IconCursoProf from "../../assets/images/ico-cursos-prof.png";
import ImgCursoProf from "../../assets/images/photo-curso-profi.jpg";
import { Link } from "react-router-dom";

const settings = {
  slidesToShow: 3,
  focusOnSelect: false,
  swipeToSlide: true,
  infinite: false,
  arrows: true,
  dots: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        dots: true,
        centerMode: true,
        centerPadding: "00px",
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        arrows: true,
        dots: true,
        centerMode: true,
        centerPadding: "00px",
        slidesToShow: 1,
      },
    },
  ],
};

const datas = [
  {
    icon: { src: IconCaixote, alt: "Caixote do Saber" },
    p: "Criado para estabelecer uma conexão entre a comunidade e a leitura, a iniciativa traz a educação como principal ferramenta na luta contra a desigualdade. Através dos livros podem sonhar, criar e acreditar que tudo é possível.",
    to: "caixote-do-saber",
    img: { src: ImgCaixote, alt: "Foto Caixote do Saber" },
  },
  {
    icon: { src: IconIdiomas, alt: "Aulas de Idiomas" },
    p: "Inglês ou espanhol? Eles escolhem a forma como podem ter contato com outra cultura, língua e costumes. As aulas contam histórias de outros continentes.",
    to: "aulas-de-idiomas",
    img: { src: ImgIdiomas, alt: "Foto Aulas de Idiomas" },
  },
  {
    icon: { src: IconReforco, alt: "Aulas de Reforço" },
    p: "Para crianças a partir de 4 anos, as aulas ajudam no reforço semanal das atividades escolares e melhor aproveitamento dos ensinamentos",
    to: "aulas-de-reforco",
    img: { src: ImgReforco, alt: "Foto Aulas de Reforço" },
  },
  {
    icon: { src: IconGradua, alt: "Gradua Nóiz" },
    p: "Um pré-vestibular social com professores voluntários, para oferecer um boa estrutura de estudo a quem não pode pagar um cursinho.",
    to: "gradua-noiz",
    img: { src: ImgGradua, alt: "Foto Gradua Nóiz" },
  },
  {
    icon: { src: IconAtendMedico, alt: "Atendimento Médico" },
    p: "Saúde é o nosso maior bem. Por isso, o acompanhamento médico é muito importante ao longo de toda a vida, tanto para o tratamento como para a prevenção de doenças.",
    to: "atendimento-medico",
    img: { src: ImgAtendMedico, alt: "Foto Atendimento Médico" },
  },
  {
    icon: { src: IconAtendPsico, alt: "Atendimento Psicológico" },
    p: "Tratar a saúde mental das pessoas é entender seus sentimentos e orientá-las a compreender suas emoções, promovendo o bem-estar e o desenvolvimento pessoal.",
    to: "atendimento-psicologico",
    img: { src: ImgAtendPsico, alt: "Foto Atendimento Psicológico" },
  },
  {
    icon: { src: IconCinema, alt: "Sessão de Cinema" },
    p: "Um dos modos de expressão cultural, o cinema é capaz de despertar ideias, sentimentos e percepções através de suas histórias. Além de ser uma poderosa ferramenta para instrução, educação e reflexão humanas.",
    to: "sessao-de-cinema",
    img: { src: ImgCinema, alt: "Foto Sessão de Cinema" },
  },
  {
    icon: { src: IconTeatro, alt: "Aulas de Teatro" },
    p: "A arte é transformadora. Capaz de integrar, socializar ideias e desenvolver sua aprendizagem de forma lúdica, o teatro desenvolve também a parte indutiva e racional através da expressão de suas emoções, levando ao conhecimento de si mesmo e do mundo o cerca.",
    to: "aulas-de-teatro",
    img: { src: ImgTeatro, alt: "Foto Aulas de Teatro" },
  },
  {
    icon: { src: IconNeuroPedia, alt: "Neuro Pediátrico" },
    p: "Ser inclusivo não pode estar só no discurso. É preparar o espaço e ter uma equipe multidisciplinar qualificada para receber e orientar as famílias da comunidade que nunca tiveram acesso ao tratamento.",
    to: "neuropediatrico",
    img: { src: ImgNeuroPedia, alt: "Foto Neuro Pediátrico" },
  },
  {
    icon: { src: IconCursoProf, alt: "Cursos Profissionalizantes" },
    p: "Profissionalizar pessoas é descobrir aptidões e gerar oportunidades que tragam pertencimento e melhoria de vida e autoestima.",
    to: "cursos-profissionalizantes",
    img: { src: ImgCursoProf, alt: "Foto Cursos Profissionalizantes" },
  },
];

const IniciativasComp = () => {
  return (
    <section className="iniciativas">
      <div className="container">
        <h2 className="subtitulo-verde">CONHEÇA NOSSAS INICIATIVAS</h2>
        <Slider {...settings} className="iniciativas-center">
          {datas.map(({ icon, p, to, img }) => {
            return (
              <div key={icon.alt}>
                <div className="iniciativas-box">
                  <div className="iniciativas-box-content">
                    <i>
                      <img src={icon.src} alt={icon.alt} />
                    </i>
                    <p>{p}</p>
                    <Link className="btn-iniciativas" to={`/iniciativas/${to}`}>
                      Saiba mais
                    </Link>
                    <img src={img.src} alt={img.alt} />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default IniciativasComp;
